.acc--table th {
  background: transparent !important;
}

th {
  padding-left: 10px !important;
}

td {
  padding: 3px 10px !important;
  outline: none !important;
}

// label {
//   margin-top: 10px !important;
// }

.form-date-picker .react-datepicker-wrapper {
  width: 100%;
  &:focus {
    box-shadow: none;
  }
}

.form__form-group {
  margin-top: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.form__form-group-label {
  margin-top: 0px !important;
  width: 30% !important;
}

.form__form-group-field {
  width: 70% !important;
}

.form__form-group-input-wrap {
  margin: 3px !important;
}

.btn {
  margin-right: 5px !important;
  padding: 5px 20px !important;
}

.form__form-group-file {
  width: 70% !important;
  padding-left: 23px !important;
}

.form__form-btns {
  margin-top: 5px !important;
}

// .bg-green {
//   background-color: #4ce1b6 !important;
// }

.text-green {
  color: $color-text; //removed !important; prev PR had #64a958
}

// .text-green {
//   color: #4ce1b6 !important;
// }

.bg-blue {
  background-color: $color-blue !important; //pr prev #70bbfd
}

.text-blue {
  color: $color-blue !important; //#70bbfd
}

.light-table {
  background-color: $color-dusky-gray;
}

.dark-table {
  background-color: $color-light-black;
}

.bg-white {
  background-color: white !important;
  color: $color-background-body !important; //#646777
}
.btn-blue {
  //background-color: $color-blue !important;//pr chg to mimic btn-outline-blue prev #3ea3fc
  background-color: transparent;
  border: 1px $color-blue solid; //PR add
  color: $color-blue !important; //PR add
  text-transform: uppercase; //PR add bc some instances missing btn class so repeating here
  letter-spacing: 0.1em; //PR add bc some instances missing btn class so repeating here
  border-radius: 2px; //PR add bc some instances missing btn class so repeating here
}

// .btn-green {
//   background-color: #24d6a3 !important;
// }

.btn-green {
  background-color: #64a958 !important;
}

.btn-w {
  width: 100px !important;
}

.bg-lightgray {
  background-color: lightgray !important;
}

.text-lightgray {
  color: lightgray !important;
}

.editable-td {
  margin: 0px auto;
  width: 250px !important;
  height: 40px;
  background-color: white;
  // float: left;
  // padding: 20px;
  position: relative;
  z-index: 10;
  cursor: default;
  word-wrap: break-word;

  .editable-cell {
    input {
      border: none;
      background-color: transparent;
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        outline: none;
      }
    }
  }
}

.editable_cell_bg_none {
  .editable-td:after {
    background-color: white !important;
  }
}

.editable-td:after {
  background-color: lightgray;
  border-radius: 3px;
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  z-index: -1;
  cursor: text;
}
//PR ADDS

.border-thin {
  border-width: thin;
  border-style: solid;
}

.pointer {
  cursor: pointer;
}

.h-max-content {
  height: max-content !important;
}

.w-max-content {
  width: max-content !important;
}

.w-full {
  width: 100% !important;
}
