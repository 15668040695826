.account {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include themify($themes) {
    background: themed("colorBackgroundBody");
  }

  .account__card {
    margin: 15px;
    background-color: $color-white;
    padding: 50px 60px;
    max-width: 520px;
    width: 100%;
    box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
    .btn {
      border: 1px solid #7d7b78;
      width: 150px;
    }
  }
}

@media screen and (max-width: 520px) {
  .account__card {
    padding: 35px 30px !important;
  }
}
