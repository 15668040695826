.annuity_documents_wrapper {
  .card {
    padding: 0;
    .table-responsive {
      th {
        background-color: $color-slate-blue;
        border: 3px solid $color-white;
        color: $color-white;
        text-transform: uppercase;
      }
      td {
        padding: 6px 10px !important;
      }
    }
  }
}
