.form__form-group-input-wrap--error-above {
  width: 100%;
  margin-bottom: 0 !important;
  .select_menu {
    border: 1px solid;
    background-color: transparent;
    padding: 0 8px;
    outline: none;
    &.border_light {
      border-color: #ebebe4;
    }
    &.border_dark {
      border-color: transparent;
    }
    &.color_light {
      color: #646777;
    }
    &.color_dark {
      color: white;
    }
  }

  .select_item {
    &.bg_light {
      background-color: #ffffff;
    }
    &.bg_dark {
      background-color: #232329;
    }
  }
}

.dropdown {
  .react-select {
    height: 32px;
    &.btn {
      letter-spacing: normal !important;
      background-color: transparent;
      color: #494846;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ced4da;
      border-radius: 0.25rem !important;
      text-transform: none !important;
      min-width: 150px;
      max-width: 300px;
      margin: 0 !important;
      padding: 6px 10px !important;

      &.btn-secondary:before {
        background-color: transparent;
      }
    }

    &.dropdown-toggle:empty::after {
      position: absolute;
      right: 12px;
    }

    &:focus {
      border: 1px solid #80bdff !important;
      box-shadow: 0 0 0 0.2rem #007bff40 !important;
    }
  }

  .dropdown-menu {
    top: initial !important;
    left: initial !important;
    transform: none !important;
    border-radius: 0.25rem !important;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: small;

    .dropdown-item {
      padding: 6px 10px;
    }
  }
}
