//moving THEMES to below color list so can ref color list
$theme-map: null;

$color-dark-fields-border: #8b9386; //33333a
$color-text: #494846; //prv 4d4d3d; prev prev 646777
$color-black-background: darken($color-text, 20%); //#232329
$color-background-body: lighten(#d7daca, 13%); //PR prev edede4; d f2f4f7
$color-hover: lighten($color-background-body, 2%); //fafbfe
$color-slate-blue: #7199bb;

$color-accent: darken($color-background-body, 10%); //aka edede4
$color-light-accent: lighten($color-accent, 10%); //55e5bb
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #858377; //prev a9b1a1; d 999
$color-additional-hover: lighten($color-additional, 10%);

$color-yellow: #ffdf65; //PR prev ffb236; prev prev f6da6e
$color-light-yellow: lighten($color-yellow, 10%);
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa; //not using so didn't alter

$color-burgundy: #721c24; //this is the orig dark red color

$color-orange: #e55304; //PR add
$color-light-orange: lighten($color-orange, 10%); //PR add
$color-dark-orange: darken($color-orange, 10%); //PR add

$color-red: #df0101; //PR prev dc3535; d ff4861
$color-dark-red: darken($color-red, 20%); //d 721c24;
$color-light-red: lighten($color-red, 10%); //d f88e7d
$color-very-light-red: lighten($color-red, 50%); //df8d7da;
$color-red-hover: darken($color-red, 10%);

$color-teal: #28acb4; //PR add; prev pr used teal 03bbc2; prev prev 51cbce
$color-light-teal: lighten($color-teal, 10%); //PR add
$color-dark-teal: darken($color-teal, 10%); //PR add

$color-blue: $color-teal; //prev PR 659cff; d 70bbfd
$color-light-blue: $color-light-teal; //prev PR #87c3f7
$color-dark-blue: $color-dark-teal; //PR add
$color-blue-hover: $color-dark-teal; //PR change

$color-true-blue: #40a2fd; //PR add bc chg blue to teal to save coding time on buttons
$color-light-true-blue: lighten($color-blue, 10%); //ditto
$color-dark-true-blue: darken($color-blue, 10%); //ditto
$color-true-blue-hover: darken($color-blue, 10%); //ditto

$color-black: #494846; //d 000000
$color-light-black: #2a2a31;
$color-gray: lighten($color-text, 25%); //#787985
$color-light-gray: lighten($color-text, 40%); //d8dfe9;
$color-dusky-gray: #f2f4f7;
$color-white: #fbfbfa; //#ffffff
$color-dusty-white: darken($color-white, 10%); //dddddd;

$color-green: #52b500; // b8e98
$color-green-hover: darken($color-green, 10%);

$themes: (
  light: (
    colorBackground: lighten($color-background-body, 12%),
    //white
    colorBackgroundBody: $color-background-body,
    //f2f4f7
    colorSidebarSub: darken($color-background-body, 3%),
    //PR add
    colorSidebarLinkActive: $color-dark-teal,
    //PR add
    inboxButtonBackground: lighten(#a9b1a1, 5%),
    inboxButtonBackgroundHover: darken(#a9b1a1, 5%),
    colorHeaderDataPicker: #a9b1a1,
    colorText: #494846,
    //prv 4d4d3d; prev prev 646777
    colorTextAdditional: #494846,
    //prv 4d4d3d; prev prev 646777
    logoImg: url(../../shared/img/logo/logo_light.svg),
    colorHover: lighten($color-teal, 25%),
    //prv #a9b1a1; prev prev #fafbfe,
    colorFolderHover: lighten($color-dark-teal, 12%),
    //prev #a9b1a1; d f0eeee
    colorBorder: lighten(#494846, 50%),
    //$colorText but can't ref that way; forgot to note orig
    colorBorder2: darken(#494846, 25%),
    //ditto; PR add
    colorIcon: $color-teal,
    //pink; dddddd
    imgInvert: invert(0%),
    colorFieldsBorder: darken(#f0f0eb, 2.5%),
    //f2f4f7
    colorBubble: rgba(240, 240, 235, 0.65),
    //rgba(242, 244, 247, 0.65)
    colorBubbleActive: rgba(248, 248, 241, 0.6),
    //aka fafaf1; old rgba(234, 238, 255, 0.6)
    colorScrollbar: darken(#f0f0eb, 25%),
    //b4bfd0; this affects active/highlight color only
    colorFitness: #4d4d3d,
    //prv 646777
    colorEmoji: darken(#4d4d3d, 20%),
    //#232329
    sidebarColor: lighten($color-background-body, 3%),
    //aliceblue
  ),
  dark: (
    colorBackground: darken(#494846, 10%),
    //prev 4d4d3d; d #232329
    colorBackgroundBody: darken(#494846, 15%),
    //#2a2a31
    colorSidebarSub: darken(#494846, 20%),
    //PR add
    colorSidebarLinkActive: $color-light-teal,
    //PR add
    inboxButtonBackground: darken(#494846, 25%),
    //#2a2a31
    inboxButtonBackgroundHover: $color-teal,
    //darken(#4d4d3d, 15%),
    colorHeaderDataPicker: $color-teal,
    //#063263,
    colorText: darken($color-background-body, 15%),
    //dddddd
    colorTextAdditional: $color-background-body,
    //999999
    logoImg: url(../../shared/img/logo/logo_dark.svg),
    colorHover: $color-teal,
    //#4d4d3d,//38373f
    colorFolderHover: #add400,
    //ffffff1a
    colorBorder: darken(#4d4d3d, 25%),
    //#222246
    colorBorder2: lighten(#4d4d3d, 5%),
    //PR add
    colorIcon: themed("colorText"),
    //605f7b
    imgInvert: invert(100%),
    colorFieldsBorder: transparent,
    //33333a
    colorBubble: rgba(150, 152, 146, 0.65),
    //rgba(68, 79, 97, 0.65)
    colorBubbleActive: rgba(175, 175, 167, 0.6),
    //rgba(92, 104, 156, 0.6)
    colorScrollbar: darken(#8b9386, 10%),
    //#606071
    colorFitness: #f0f0eb,
    //ffffff
    colorEmoji: #f0f0eb,
    //ffffff
    sidebarColor: darken(#4d4d3d, 25%),
    //#232329
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$directions: (
  ltr: (
    direction: ltr,
    right: right,
    left: left,
    margin-right: "margin-right",
    margin-left: "margin-left",
    padding-left: "padding-left",
    padding-right: "padding-right",
    border-top-left-radius: "border-top-left-radius",
    border-bottom-left-radius: "border-bottom-left-radius",
    border-top-right-radius: "border-top-right-radius",
    border-bottom-right-radius: "border-bottom-right-radius",
    border-left: "border-left",
    border-right: "border-right",
    translate: "translate(-50%, -50%);",
    mirrorY: "scale(1, 1)",
    border-right-color: "border-right-color",
    transform-sidebar: "rotate(90deg)",
    flex-flow: row nowrap,
    row: "row",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    "-": "-",
  ),
  rtl: (
    direction: rtl,
    right: left,
    left: right,
    margin-right: "margin-left",
    margin-left: "margin-right",
    padding-left: "padding-right",
    padding-right: "padding-left",
    border-top-left-radius: "border-top-right-radius",
    border-bottom-left-radius: "border-bottom-right-radius",
    border-top-right-radius: "border-top-left-radius",
    border-bottom-right-radius: "border-bottom-left-radius",
    border-left: "border-right",
    border-right: "border-left",
    translate: "translate(50%, -50%);",
    mirrorY: "scale(1, -1)",
    border-right-color: "border-left-color",
    transform-sidebar: "rotate(-90deg)",
    flex-flow: row-reverse nowrap,
    row: "row-reverse",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    "-": "+",
  ),
);

@mixin directify($directions) {
  @each $direction, $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($directions, $direction), "#{$key}");
        $direction-map: map-merge(
          $direction-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}

$borders: (
  round-borders-on: (
    borderRadius: 15px,
  ),
  round-borders-off: (
    borderRadius: 0px,
    //5px
  ),
);

@mixin borderify($borders) {
  @each $border, $map in $borders {
    .#{$border} & {
      $border-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($borders, $border), "#{$key}");
        $border-map: map-merge(
          $border-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $border-map: null !global;
    }
  }
}

@function bordered($key) {
  @return map-get($border-map, $key);
}

$shadows: (
  blocks-shadows-on: (
    blocksShadows: 0 10px 30px 1px rgba(0, 0, 0, 0.06),
  ),
  blocks-shadows-off: (
    blocksShadows: none,
  ),
);

@mixin shadowify($borders) {
  @each $shadow, $map in $shadows {
    .#{$shadow} & {
      $shadow-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($shadows, $shadow), "#{$key}");
        $shadow-map: map-merge(
          $shadow-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $shadow-map: null !global;
    }
  }
}

@function shadowed($key) {
  @return map-get($shadow-map, $key);
}

.text-light {
  @include themify($themes) {
    color: themed("colorText") !important;
  }
}
.text-danger {
  font-weight: 400;
}
