.settlement-report-wrapper {
  .w-max-content {
    .card {
      padding: 0;
    }
  }
  table {
    tbody,
    tfoot,
    tr,
    td,
    th {
      border: 0 !important;
    }
  }
}
