$blue: #005a9c;

.annuity_document_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  .left_container {
    border: 3px solid #800080;
    width: 55%;
    a {
      display: block;
      .learn_more {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .annuity_btn {
          background-color: #002155;
          z-index: unset;
        }
      }
    }
  }
  .divider {
    border: 2px solid #a9a9a9;
  }
  .right_container {
    display: flex;
    align-items: center;
    max-width: 220px;
    padding: 15px;
    border-radius: 25px;
    background-color: $blue;
  }
}

.annuity_policy_wrapper {
  flex-grow: 1;
  font-family: "Manrope", "-apple-system", "BlinkMacSystemFont", sans-serif;
  .accumulation_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
    background-color: #008000;
    border-radius: 10px;
    .accumulation_title {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
    .divider {
      border: 1px solid #ffffff;
      margin: 10px;
    }
    .contract_details {
      display: flex;
      justify-content: space-between;
      sup {
        top: -3px;
      }
    }
  }
  .bg_color {
    background-color: $blue;
  }
  .icon-blue {
    color: $blue;
  }
  .rounded {
    border-radius: 6px !important;
  }
  .text-transform-none {
    text-transform: none;
  }

  .accumulation_value_wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .tooltip_wrapper {
    display: flex;
    gap: 2px;
  }

  .width-100 {
    width: 100px;
  }

  .fs-small {
    font-size: small;
  }

  .card,
  .card-body {
    padding: 0;
    position: unset;
    table {
      margin: 0 !important;
      th,
      td,
      tr,
      tbody {
        border: 0 !important;
      }
      tr:nth-child(even),
      th {
        background-color: whitesmoke !important;
      }
      tr {
        td {
          vertical-align: middle;
          padding: 0 !important;
          div {
            margin: 20px 10px;
          }
        }
      }
    }
  }

  .form__form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form__form-group-label {
      width: 70% !important;
    }
    .form__form-group-field {
      margin: 0;
      width: auto !important;
      height: auto !important;
      input {
        height: 45px;
        width: 45px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  .bg-income_projection {
    background: linear-gradient(to bottom, white 50%, whitesmoke);
  }
  .w-60 {
    width: 60px !important;
  }
  .bg-inactive {
    background-color: #e6e6e6;
  }

  .annuity-switch {
    display: flex;
    gap: 15px;
    margin-top: 6px;
    justify-content: end;
    align-items: center;

    .toggle-btn__input-label {
      margin: 0 !important;
      height: 26px;
      width: 56px;
      background-color: $blue;

      &::after {
        width: 25px;
        height: 25px;
        box-shadow: 0 0 3px 3px rgba(0, 123, 255, 0.9);
      }
    }

    .toggle-btn {
      width: auto;
    }

    .toggle-btn__input:checked ~ .toggle-btn__input-label {
      &:after {
        background: #ffffff;
      }
    }
  }
}

.annuity_summary_wrapper {
  flex-grow: 1;
  font-family: "Manrope", "-apple-system", "BlinkMacSystemFont", sans-serif;

  .annuity_provider {
    height: 80px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px 30px;
    background-color: $blue;
    border-radius: 4px;
  }
}

.annuity_logout {
  text-align: center;
  font-size: x-large;
  color: blue;
  font-weight: 500;
  margin-top: 100px;
}

.annuity_pie_chart {
  display: flex;
  justify-content: space-between;
  height: 200px;
  position: relative;

  .chart_legends {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .legends_label {
      display: flex;
      align-items: center;
      gap: 15px;

      .legend_icon {
        padding: 10px;
      }

      p {
        font-weight: bold;
        color: #999999;
      }

      &:hover {
        p {
          color: #000000;
        }
      }
    }
  }

  .pie_wrapper {
    position: relative;

    .label-overlay {
      width: 80px;
      position: absolute;
      left: 38%;
      top: 34%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      pointer-events: none;

      h6 {
        font-size: 15px;
        font-weight: 600;
        color: #7199bb;
      }

      .subtitle {
        font-size: 12px;
        font-weight: 500;
        margin-top: 3px;
      }
    }
  }
}

.annuityTabs {
  flex-grow: 1;
  background-color: #ffffff;

  .product_logo {
    margin: 10px;
    height: 10%;
    width: 10%;
  }

  .MuiTabs-indicator {
    background-color: gray;
  }
}
