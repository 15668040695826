.product_page_wrapper {
  gap: 3px;
  .form__form-group-input-wrap--error-above {
    width: 100%;
    margin-bottom: 0 !important;
    .select_menu {
      border: 1px solid;
      background-color: transparent;
      padding: 0 8px;
      outline: none;
      &.border_light {
        border-color: #ebebe4;
      }
      &.border_dark {
        border-color: transparent;
      }
      &.color_light {
        color: #646777;
      }
      &.color_dark {
        color: white;
      }
    }

    .select_item {
      &.bg_light {
        background-color: #ffffff;
      }
      &.bg_dark {
        background-color: #232329;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.product_wrapper {
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.product_factor_form_wrapper {
  max-width: 700px;
}

.editable-cell {
  &#minAge {
    input {
      text-align: left;
    }
    .percentage-sign {
      display: none;
    }
  }
}

.editable-cell {
  &#defaultPercent {
    padding: 5px;
    background-color: lightgray;
  }
}
